#skip-nav {
  display: inline-block;
  position: absolute;
  top: 1em;
  left: -15em;
  padding: 0.5em;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 100;
}

#skip-nav:focus {
  left: 1em;
}
